/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: 92%;
}
.cb-home {
  margin: 32px auto;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-scroll-triggered--active .cb-home {
  margin: 10px auto;
  width: 106px;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--header.cb-scroll-triggered--active {
  border-color: #49443f;
}
#edit .section--header {
  position: relative;
  z-index: 2;
}
.cb-page-layout1 .section--multimood,
.cb-page-layout4 .section--multimood {
  margin-top: 130px;
}
#edit .cb-page-layout1 .section--multimood,
#edit .cb-page-layout4 .section--multimood {
  margin-top: 0;
}
.northcontent {
  float: left;
  width: 68%;
  margin-left: 17%;
  margin-right: 17%;
}
.farnorthwidth {
  width: 480px;
}
.cb-page-layout4 .farnorthwidth {
  width: 254px;
  padding: 20px 0;
}
#toplinks {
  margin: 17px 0;
}
.footpart {
  width: 27%;
}
.footpart.footpart--large {
  width: 44%;
}
@media (max-width: 1023px) {
  .farnorth h2 {
    font-size: 33px;
    line-height: 1.15151515;
    box-shadow: 10px 0 0 #fff, -10px 0 0 #fff;
  }
  .cb-page-layout4 .farnorth h2 {
    box-shadow: 10px 0 0 #00263A, -10px 0 0 #00263A;
  }
  .side h2 {
    font-size: 21px;
    line-height: 1.23809524;
    box-shadow: 10px 0 0 #173F35, -10px 0 0 #173F35;
  }
  .side .seam h2 {
    box-shadow: 10px 0 0 #00263A, -10px 0 0 #00263A;
  }
  .side .pure h2 {
    box-shadow: 10px 0 0 #6F2B3F, -10px 0 0 #6F2B3F;
  }
  .farnorth .text-section--h2 {
    padding: 0 16px;
  }
  .side .text-section--h2 {
    padding: 0 10px;
  }
  .south h3 {
    font-size: 21px;
    line-height: 1.23809524;
  }
  .newsletter__title,
  .services .meta {
    font-size: 21px;
    line-height: 1.23809524;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 1.5%;
  margin-left: 1.5%;
  width: 97%;
}
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim {
  width: 47%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.area > .slim .part {
  width: 100%;
}
.area > .slim .tiny {
  width: 100%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.side .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side > .slim {
  width: 48%;
}
.side > .slim .foot,
.side > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.side > .slim .part {
  width: 100%;
}
.side > .slim .tiny {
  width: 100%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.north .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north > .slim .part {
  width: 100%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.south .foot {
  margin-right: 3%;
  margin-left: 3%;
}
.south .part {
  margin-right: 3%;
  margin-left: 3%;
  width: 94%;
}
.south > .slim {
  width: 31.33333333%;
}
.south > .slim .foot,
.south > .slim .part {
  margin-right: 9.38297872%;
  margin-left: 9.38297872%;
}
.south > .slim .part {
  width: 81.23404255%;
}
.south > .slim .tiny {
  width: 81.23404255%;
}
div.farnorth {
  float: left;
  width: 100%;
}
div.south {
  width: 103%;
  margin-left: -1.5%;
}
div.side {
  margin-left: -1%;
  width: 102%;
}
@media (max-width: 1023px) {
  div.main {
    margin-left: -1%;
    width: 102%;
  }
}
div.south div.unit.wide {
  width: 68%;
  margin-left: 17%;
  margin-right: 17%;
}
div.south div.unit.wide div.part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 1023px) {
  div.main div.wide {
    width: 47%;
  }
  div.main div.seam div.part,
  div.main div.flat div.part,
  div.main div.edge div.part {
    width: 88%;
    margin-left: 6%;
    margin-right: 6%;
  }
  div.north div.unit div.part {
    margin-left: 0;
    margin-right: 0;
  }
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */